<template>
    <LoadedContent class="menus" :loaded="menuLoaded" :error="menuError">
        <h1 >{{ $t('titles.select_menus') }}</h1>
        <div class="menu-block" v-if="!menus || menus.length === 0">
            {{ $t("cms.no_menu_in_project") }}
        </div>
        <div v-else class="menu-block" v-for="(menu,index) in menus" :key="index">
            <h3>
            <RouterLinkLocal class="menu-header" :link="menuLink(menu)">
                {{ menu.name }}
            </RouterLinkLocal>
            </h3>
            <div class="description">
                {{ menu.description }}
            </div>
            <RouterLinkLocal class="link" :link="menuLink(menu)">
                <BaseButton class="btn-warning"  type="button" >
                    {{ $t("controls.go_to_menu") }}
                </BaseButton>
            </RouterLinkLocal>
        </div>
    </LoadedContent>
</template>

<script>
import { useStore } from 'vuex'
import LoadedContent from '@common/components/base/LoadedContent'
import { computed } from 'vue';


export default {
    name: "Menus",
    components: {
        LoadedContent,
    },
    setup(){
        const store = useStore();

        store.dispatch('menu/fetchItems',{});

        const menus = computed(() => store.getters['menu/getItems']);

        const menuLoaded = computed(() => store.getters['menu/isLoaded']);
        const menuError = computed(() => store.getters['menu/isError']);

        const menuLink = (menu) => 'url.project/:project_code/url.menus/!'+menu.item_key;

        return {
            menus,
            menuLoaded,
            menuError,
            menuLink
        }
    }
}
</script>

<style scoped>
.menu-block{
    margin: 2rem auto;
    background: #e9fff3;
    padding: 1rem;
    box-shadow: 1px 1px 6px 0px #177542;
    box-shadow: 1px 1px 6px 0px var(--primary-color);
    max-width: 30rem;
    width: 100%;
}
.menu-block>h3{
    margin: 0 0 1rem 0;
    border-bottom: 1px solid #a5d0b9;
    padding: 0.5rem;
}
.menu-block>h3>a{
    color: black;
    color: var(--dark-color);
}
.link{
    float: right;
    background: lightgrey;
}
.description{
    margin: 1rem;
}

</style>